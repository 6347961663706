import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import "./scss/style.scss";
import ToastContainerComponent from "./components/toast/ToastContainerComponent";
import { VIPRoutes, defaultRoutes } from "./routes/routes";
import { useAuth } from "src/hooks/auth/useAuth";

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const selectRole = (userId) => {
    switch (userId) {
      case 4: {
        return VIPRoutes;
      }
      case 5: {
        return VIPRoutes;
      }
      case 6: {
        return VIPRoutes;
      }
      default: {
        return defaultRoutes;
      }
    }
  };
  const { token, setToken } = useAuth();
  const routesResultData = selectRole(token?.userType);
  const routesResult = useRoutes(routesResultData);
  return (
    <>
      <Suspense fallback={<Loading />}>{routesResult}</Suspense>
      <ToastContainerComponent />
    </>
  );
};

export default App;
