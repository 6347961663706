import React, { createContext } from 'react'
import { useLocalStorage } from '../../hooks/auth/useLocalStorage'

const AuthContext = createContext({
  token: '',
  setToken: () => {},
})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage('token', null)

  return <AuthContext.Provider value={{ token, setToken }}>{children}</AuthContext.Provider>
}

export default AuthContext
