import React from "react";
import { Navigate } from "react-router-dom";
import { ProtectedRoute } from "../components/protected-route/ProtectedRoute";

const Dashboard = React.lazy(() => import("src/views/dashboard/Dashboard"));
const Login = React.lazy(() => import("src/views/login/Login"));
const AuthLayout = React.lazy(() => import("src/components/layout/AuthLayout"));
const MainLayout = React.lazy(() => import("src/components/layout/MainLayout"));

export const VIPRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: "/", exact: true, name: "Home" },
      { path: "/dashboard", name: "Dashboard", element: <Dashboard /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export const defaultRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];
