import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/login/AuthContext";

export const ProtectedRoute = (props) => {
  const { children } = props;
  const { token } = useContext(AuthContext);
  if (!token?.accessToken) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};
