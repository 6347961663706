import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { AuthProvider } from 'src/context/login/AuthContext'
import store from './store'
import { HashRouter } from 'react-router-dom'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
          <HashRouter>
            <App />
          </HashRouter>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
)
reportWebVitals()
